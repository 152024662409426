import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { Footer } from "./components/Footer";
import { Header } from "./components/Header";
import { Home } from "./pages/Home";
import { Contact } from "./pages/ContactUs/Contact";
import { Blog } from "./pages/ContactUs/Blog";
import { Service } from "./pages/ServicesAndAmenities/Service";
import { Facility } from "./pages/ServicesAndAmenities/Facility";
import { AssistedCare } from "./pages/ServicesAndAmenities/AssistedCare";
import Photo from './pages/Photo';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import About from './pages/About';
import OurApproach from './pages/OurApproach';

// import './App.css';

function App() {
    return (
        <div className="App">
            <BrowserRouter>
                <Header />
                <ToastContainer />
                <Routes>
                    <Route path="/" element={<Home />} />

                    <Route path="/photos" element={<Photo />} />
                    <Route path="/about" element={<About />} />
                    <Route path="/about/our-approach" element={<OurApproach />} />

                    <Route path="/our-services" element={<Service />} />
                    <Route path="/services-and-facilities/facilities" element={<Facility />} />
                    <Route path="/services-and-facilities/assisted-care" element={<AssistedCare />} />
                    
                    <Route path="/contact" element={<Contact />} />
                    <Route path="/contact-us/blog" element={<Blog />} />
                    <Route path="*" element={<Navigate to="/" replace />} />
                </Routes>
                <Footer />
            </BrowserRouter>
        </div>
    );
}

export default App;
