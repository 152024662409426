import React from 'react'
import HelmetContainer from '../components/Helmet/HelmetContainer';
import Breadcrumbs from '../components/Breadcrumbs';

export const About = () => {
    return (
        <>
            <HelmetContainer title="About Olepaipai" />
            <Breadcrumbs title="About Olepaipai" />

            <section className="about-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7">
                            <div className="video-wrapper mt-90">
                                <div className="video-overlay">
                                    <img src="assets/img/banner/2.png" alt="AAbout Olepaipai" />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5 pt-5">
                            <div className="about-text mt-5">
                                <div className="section-title">
                                    <h3>INTRODUCTION</h3>
                                    <p>  
                                        Olepaipai invites you to embrace your next chapter and live well like never before in our well-appointed apartment community. We offer Assisted Living for residents who want to focus on the more important things in life and Memory Care for loved ones to make their every moment a positive one. Our elegantly modern community redefines retirement living with beautifully appointed living spaces, five-star services, and resort-worthy amenities.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="about-area pb-5">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="about-text">
                                <div className="section-title">
                                    <h3>OUR MISSION</h3>
                                    <p>              
                                        People matter most in life. and our purpose is to make life's journey wonderful. We are engaged in providing an inspirational place for our residents while creating a spectacular living experience that is safe. healthy. and happy- one that truly fosters purpose and dignity.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="about-text">
                                <div className="section-title">
                                    <h3>OUR VISION</h3>
                                    <p>  
                                        Our vision is to be the community's finest care provider by giving our residents the best living experience and providing their loved ones' full peace-of-mind.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default About;