import React from 'react'
import HelmetContainer from '../../components/Helmet/HelmetContainer';
import Breadcrumbs from '../../components/Breadcrumbs';


export const Facility = () => {
    return (
        <>
            <HelmetContainer title="FACILITIES" />
            <Breadcrumbs title="FACILITIES" />

            




            <section className="about-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5">
                            <div className="about-text">
                                <div className="section-title">
                                    <h3>FACILITIES</h3>
                                    <p>
                                        Olepaipai's facility is thoughtfully designed to provide a welcoming and enriching environment for our residents. Our beautifully landscaped outdoor areas, well-appointed common spaces, and a wide range of amenities ensure that residents enjoy a comfortable and engaging lifestyle. With utilities included, diverse dining options, and numerous on-site conveniences, Olepaipai offers a truly exceptional living experience.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-7">
                            <div className="video-wrapper mt-90">
                                <div className="video-overlay">
                                    <img src="assets/img/banner/facility.png" alt="Facility | Olepaipai" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-5">
                        <div className="room-facilities">
                            <h3 className="room-details-title">Our Facilities</h3>
                            <div className="single-facility">
                                <span><i className="zmdi zmdi-check"></i>Utilities included (excluding phone)<br/></span>
                                <span><i className="zmdi zmdi-check"></i>Thoughtfully designed common areas<br/></span>
                                <span><i className="zmdi zmdi-check"></i>Basic cable<br/></span>
                                <span><i className="zmdi zmdi-check"></i>Beautifully landscaped outdoor areas<br/></span>
                            </div>
                            <div className="single-facility">
                                <span><i className="zmdi zmdi-check"></i>Daily social. cultural & recreational<br/> activities</span>
                                <span><i className="zmdi zmdi-check"></i>Transportation available for appointments. <br/>shopping. dining & outings</span>
                                <span><i className="zmdi zmdi-check"></i>Exercise. fitness & wellness programs</span>
                                <span><i className="zmdi zmdi-check"></i>3 gourmet meals supervised by a registered dietitian & <br/>prepared by in-house chefs</span>
                            </div>
                            <div className="single-facility">
                                <span><i className="zmdi zmdi-check"></i>Diverse menu options & dining venues including pubs & bistros</span>
                                <span><i className="zmdi zmdi-check"></i>Beauty/barber shops. concierge services & other onsite conveniences</span>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default Facility;