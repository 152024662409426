import React from 'react'
import HelmetContainer from '../../components/Helmet/HelmetContainer';
import Breadcrumbs from '../../components/Breadcrumbs';

export const Blog = () => {
    return (
        <>
            <HelmetContainer title="Blog" />
            <Breadcrumbs title="Blog" />

            <section className="room-area pt-90">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="room-list">
                                <div className="row">
                                    <div className="col-lg-5 col-md-6">
                                        <a href="room-details.html"><img src="assets/img/room/10.jpg" alt="" /></a>
                                    </div>
                                    <div className="col-lg-7 col-md-6 align-self-center">
                                        <div className="room-list-text">
                                            <h3><a href="room-details.html">Single Room</a></h3>
                                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod hytt tempor incididuc utd labore et dolore magna aliqua. Ut enim ad minim yttveniam, quis nostrud exercitation csdd ullamco laboris  ed do eiusmod hytt </p>
                                            <h4>Room Facility</h4>
                                            <div className="room-service">
                                                <p>Breakfast Include , Free wi-fi , Private Balcony , Free Newspaper , Full AC, Beach View , Sweeming Pool</p>
                                                <div className="p-amount">
                                                    <span>$220</span>
                                                    <span className="count">Per Night</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="room-list">
                                <div className="row">
                                    <div className="col-lg-5 col-md-6">
                                        <a href="room-details.html"><img src="assets/img/room/11.jpg" alt="" /></a>
                                    </div>
                                    <div className="col-lg-7 col-md-6 align-self-center">
                                        <div className="room-list-text">
                                            <h3><a href="room-details.html">Royal Suit</a></h3>
                                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod hytt tempor incididuc utd labore et dolore magna aliqua. Ut enim ad minim yttveniam, quis nostrud exercitation csdd ullamco laboris  ed do eiusmod hytt </p>
                                            <h4>Room Facility</h4>
                                            <div className="room-service">
                                                <p>Breakfast Include , Free wi-fi , Private Balcony , Free Newspaper , Full AC, Beach View , Sweeming Pool</p>
                                                <div className="p-amount">
                                                    <span>$550</span>
                                                    <span className="count">Per Night</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default Blog;