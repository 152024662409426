import React from 'react';
import { Helmet } from 'react-helmet';

export const HelmetContainer = ({ title, description }) => {
    return (
        <Helmet>            
            <title>Olepaipai | {title}</title>
            {description && <meta name="description" content={description} />}
        </Helmet>
    )
}
export default HelmetContainer;