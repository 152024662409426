import React, { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';


export const ContactForm = () => {
    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        email: '',
        phone_number: '',
        message: ''
    });
    const [loading, setLoading] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.currentTarget;
        setFormData({ ...formData, [name]: value });
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            const response = await axios.post('http://127.0.0.1:8000/landing/contact/', formData);
            if (response.status === 201) {
                toast.success('Your message has been sent successfully!');
                clearForm();
            }
        } catch (error) {
            toast.error('An error has occurred. Try Again.');
        }
        setLoading(false);
    };

    const clearForm = () => {
        setFormData({
            first_name: '',
            last_name: '',
            email: '',
            phone_number: '',
            message: ''
        });
    };
    return (
        <>
            <section className="contact-form-area pt-90">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4">
                            <h4 className="contact-title">Contact info</h4>
                            <div className="contact-text">
                                <p><span className="c-icon"><i className="zmdi zmdi-phone"></i></span><span className="c-text">+254103333332/ +254783333332</span></p>
                                <p><span className="c-icon"><i className="zmdi zmdi-email"></i></span><span className="c-text">info@olepaipai.org</span></p>
                                <p><span className="c-icon"><i className="zmdi zmdi-pin"></i></span><span className="c-text">Daima Towers, 14TH Flr. Uganda Road, Eldoret</span></p>
                            </div>
                            <h4 className="contact-title">social media</h4>
                            <div className="link-social">
                                <a href="https://www.facebook.com/"><i className="zmdi zmdi-facebook"></i></a>
                                <a href="https://www.rss.com/"><i className="zmdi zmdi-rss"></i></a>
                                <a href="https://plus.google.com/"><i className="zmdi zmdi-google-plus"></i></a>
                                <a href="https://www.pinterest.com/"><i className="zmdi zmdi-pinterest"></i></a>
                                <a href="https://www.instagram.com/"><i className="zmdi zmdi-instagram"></i></a>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <h4 className="contact-title">send your massage</h4>
                            <form id="contact-form" onSubmit={handleSubmit}>
                                <div className="row">
                                    <div className="col-md-6">
                                        <input type="text" name="first_name" placeholder="First Name" value={formData.first_name} onChange={handleChange} required />
                                    </div>
                                    <div className="col-md-6">
                                        <input type="text" name="last_name" placeholder="Last Name" value={formData.last_name} onChange={handleChange} required />
                                    </div>
                                    <div className="col-md-6">
                                        <input type="email" name="email" placeholder="Email" value={formData.email} onChange={handleChange} required />
                                    </div>
                                    <div className="col-md-6">
                                        <input type="text" name="phone_number" placeholder="Phone Number" value={formData.phone_number} onChange={handleChange} required />
                                    </div>
                                    <div className="col-md-12">
                                        <textarea name="message" cols="30" rows="10" placeholder="Message here" value={formData.message} onChange={handleChange} required></textarea>
                                        {/* <button type="submit" className="default-btn">SUBMIT</button> */}
                                        <button className="default-btn" type="submit" disabled={loading}>
                                            {loading ? 'Sending...' : 'Send'}
                                        </button>
                                    </div>
                                </div>
                            </form>
                            {/* <p className="form-messege"></p> */}
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default ContactForm;