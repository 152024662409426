import React from 'react'
import ContactForm from './ContactUs/ContactForm'


export const Home = () => {

    return (
        <>
            <section className="slider-area">
                <div className="slider-wrapper">
                    <div className="single-slide" style={{ backgroundImage: "url('assets/img/slider/1.png')" }}>
                        <div className="banner-content overlay">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-10 mx-auto">
                                        <div className="text-content-wrapper">
                                            <div className="text-content text-center">
                                                <h1 className="text-white">HOME CARE & MEMORY CARE</h1>
                                                {/* <p>
                                                    REDEFINED RETIREMENT LIVING
                                                </p> */}
                                                {/* <div className="banner-btn">
                                                    <a className="default-btn" href="room-grid.html">explore</a>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="single-slide" style={{ backgroundImage: "url('assets/img/slider/slider.png')" }}>
                        <div className="banner-content overlay">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-10 mx-auto">
                                        <div className="text-content-wrapper slide-two">
                                            <div className="text-content text-center">
                                                <h1 className="pt-180">HOME CARE & MEMORY CARE</h1>
                                                {/* <p>
                                                    REDEFINED RETIREMENT LIVING
                                                </p> */}
                                                {/* <div className="banner-btn">
                                                    <a className="default-btn" href="room-grid.html">explore</a>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section className="about-area pb-5">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7">
                            <div className="video-wrapper mt-90">
                                <div className="video-overlay">
                                    <img src="assets/img/banner/2.png" alt="About Olepaipai" />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5 pt-5">
                            <div className="about-text">
                                <div className="section-title">
                                    <h3>About Olepaipai</h3>
                                    <p>              
                                        Welcome to Olepaipai, where we redefine retirement living with luxury, comfort, and care. Our community offers Assisted Living and Memory Care services designed to enhance the quality of life for our residents. We provide personalized assistance, wellness visits, and comprehensive care to ensure our residents' well-being. Our beautifully appointed apartments, diverse dining options, and resort-worthy amenities create a vibrant and enriching environment. Join us at Olepaipai and embrace a new chapter of life with purpose, dignity, and joy.
                                    </p>
                                    <div className="banner-btn mb-5">
                                        <a className="default-btn" href="/about">ABOUT OLEPAIPAI</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section className="advertise-area bg-3 overlay-dark">
                <div className="container">
                    <div className="row">
                    <div className="col-12">
                        <div className="advertise-text">
                            <h1 style={{ fontSize: '1.6rem' }}>
                            From hospital discharge to full recovery, we’ll be by your side, providing the physical and emotional support you need.
                            <br /><br /> Let us help you on your road to recovery
                            </h1>
                        </div>
                    </div>
                    </div>
                </div>
            </section>



            <section className="about-area pb-5">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5">
                            <div className="about-text">
                                <div className="section-title">
                                    <h3>Who benefits from our unique approach?</h3>
                                    <p>
                                    Every older adult can benefit from our 360-degree approach to care. Even clients who only require a little help can blossom through balanced care that focuses on total wellness. The Balanced Care Method informs every Care Plan and level of service we provide, including:
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-7">
                            <div className="about-text">
                                <div className="section-title">
                                    <div className="room-facilities">
                                        <div className="single-facility">
                                            <span><i className="zmdi zmdi-check"></i>Respite Care</span>
                                            <span><i className="zmdi zmdi-check"></i>Alzheimer’s Disease and Dementia</span>
                                            <span><i className="zmdi zmdi-check"></i>Heart Health</span>
                                            <span><i className="zmdi zmdi-check"></i>Cancer</span>
                                            <span><i className="zmdi zmdi-check"></i>End-of-Life Care</span>
                                        </div>
                                        <div className="single-facility">
                                            <span><i className="zmdi zmdi-check"></i>24/7 Care</span>
                                            <span><i className="zmdi zmdi-check"></i>Parkinson’s Disease</span>
                                            <span><i className="zmdi zmdi-check"></i>Care Following a Stroke</span>
                                            <span><i className="zmdi zmdi-check"></i>Hospital to Home</span>
                                        </div>
                                    </div>
                                    <div className="banner-btn pt-2 pb-3">
                                        <a className="default-btn" href="/about/our-approach">OUR APPROACH</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section className="about-area bg-light pb-5">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-8">
                            <div className="about-text">
                                <div className="section-title">
                                    <h3>Get the care you need in the home you love.</h3>
                                    <p>              
                                    From help a few days a week to 24/7 support for more complex needs, we have an in-home care solution that’s right for you.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            

            {/* <section className="about-area pb-5" style={{background: '#f1f1f1'}}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5 mb-4">
                            <div className="about-text">
                                <div className="section-title pt-5">
                                    <h3>ASSISTED CARE</h3>
                                    <p>              
                                        Our Assisted Care services at Olepaipai are designed to provide personalized support tailored to each resident's needs. We offer comprehensive assistance with daily activities, medication management, and coordination with healthcare providers, ensuring a comfortable and fulfilling lifestyle. Our dedicated team creates a supportive environment where residents can thrive, participate in engaging activities, and enjoy a high quality of life.
                                    </p>
                                    <div className="banner-btn">
                                        <a className="default-btn" href="/services-and-facilities/assisted-care">LEARN MORE</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-7 mb-5">
                            <div className="video-wrapper mt-90">
                                <div className="video-overlay">
                                    <img src="assets/img/banner/Assisted-care.png" alt="ASSISTED CARE | Olepaipai" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}


            {/* <section className="about-area pb-5">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7 mb-5">
                            <div className="video-wrapper mt-90">
                                <div className="video-overlay">
                                    <img src="assets/img/banner/services.png" alt="SERVICES & FACILITIES | Olepaipai" />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5">
                            <div className="about-text mt-3">
                                <div className="section-title pt-5">
                                    <h3>SERVICES & FACILITIES</h3>
                                    <p>              
                                    At Olepaipai, we offer comprehensive living services including personalized assistance, medication management, and coordination with healthcare providers, ensuring our residents' comfort and well-being. Our facility features beautifully landscaped outdoor areas, diverse dining options, and numerous on-site conveniences, creating an exceptional living experience.
                                    </p>
                                    <div className="banner-btn">
                                        <a className="default-btn" href="/services-and-facilities/living-services">SERVICES</a>
                                        <a className="default-btn" href="/services-and-facilities/facilities">FACILITIES</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}


            <section className="pricing-area ptb-90">
                <div className="container">
                    <div className="row">
                        <div className="col-md-9 mx-auto">
                            <div className="section-title text-center">
                                <h3 style={{ fontSize: '0.9rem' }}>Our Services</h3>
                                <h1 style={{ fontSize: '2rem' }} className='mb-3'>
                                    Exceptional Care at Home for Older Adults
                                </h1>
                                <p>
                                    First, we get to know you and your care needs, then we design a flexible Care Plan just for you.<br />
                                    You’ll get the in-home care solution you need—and always be informed and in control.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4 col-sm-6">
                            <div className="single-pricing">
                                <div className="package-name">
                                    <h3>Home Care</h3>
                                    <h1 style={{ fontSize: '1.1rem' }} className='p-3'>
                                        From a few hours a day of help to 24/7 support, we will design a Care Plan to meet your specific needs. Our high-quality in-home care includes:
                                    </h1>
                                </div>
                                <div className="package-offer" style={{ height: '320px' }}>
                                    <span><p>Companionship</p></span>
                                    <span><p>Help At Home</p></span>
                                    <span><p>Respite Care</p></span>
                                    <span><p>24/7 Care</p></span>
                                    <span><p>Live-In Care</p></span>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6">
                            <div className="single-pricing best-offer">
                                <div className="package-name">
                                    <h3>Memory Care at Home*</h3>
                                    <h1 style={{ fontSize: '1.1rem' }} className='p-3'>
                                        Our caregivers are expertly trained to support people living with any type of cognitive decline from mild memory problems to advanced dementia.
                                    </h1>
                                </div>
                                <div className="package-offer" style={{ height: '320px' }}>
                                    <span><p>Mild Cognitive Impairment (MCI)</p></span>
                                    <span><p>Alzheimer’s Disease</p></span>
                                    <span><p>Vascular Dementia</p></span>
                                    <span><p>Lewy Body Dementia</p></span>
                                    <span><p>Frontotemporal Dementia</p></span>
                                    <span><p>Parkinson’s Disease Dementia</p></span>
                                    <p className='p-2'>
                                        * Personal care and non-medical support for people living with cognitive impairment
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6">
                            <div className="single-pricing">
                                <div className="package-name">
                                    <h3>Specialized Care</h3>
                                    <h1 style={{ fontSize: '1.1rem' }} className='p-3'>
                                        We support people living with specific medical conditions and unique needs, providing the best care so our clients can live safely at home.
                                    </h1>
                                </div>
                                <div className="package-offer" style={{ height: '320px' }}>
                                    <span><p>Parkinson’s Disease</p></span>
                                    <span><p>Heart Health</p></span>
                                    <span><p>After A Stroke</p></span>
                                    <span><p>Cancer</p></span>
                                    <span><p>End-Of-Life Care</p></span>
                                    <span><p>Hospital To Home</p></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="banner-btn pt-5 text-center">
                        <a className="default-btn" href="/our-services">OUR SERVICES</a>
                    </div>
                </div>
            </section>



            <section className="advertise-area bg-3 overlay-dark">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="advertise-text">
                                <h1 style={{ fontSize: '1.6rem' }}>Our Hospital-to-Home care solution sets you up to recover safely and comfortably at home.</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </section>




            <section className="event-area pt-90">
                <div className="container">
                    <div className="row">
                        <div className="col-md-10 mx-auto">
                            <div className="section-title text-center">
                                <h3>Photos of Olepaipai</h3>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row event-items">
                    <div className="single-event col-md-8 col-12 conference">
                            <div className="single-event-item">
                                <img src="assets/img/galery/1.png" alt="Olepaipai" />
                            </div>
                        </div>
                        <div className="single-event col-md-4 small col-12 music">
                            <div className="single-event-item">
                                <img src="assets/img/galery/3.png" alt="Olepaipai" />
                            </div>
                        </div>
                        <div className="single-event col-md-4 small col-12 birthday">
                            <div className="single-event-item">
                                <img src="assets/img/galery/4.png" alt="Olepaipai" />
                            </div>
                        </div>
                        <div className="single-event col-md-8 col-12 wedding">
                            <div className="single-event-item">
                                <img src="assets/img/galery/2.png" alt="Olepaipai" />
                            </div>
                        </div>
                    </div>
                    <div className="row mt-5">
                        <div className="header-links  mb-5">
                            <div className="banner-btn text-center">
                                <a className="default-btn" href="/photos">View More</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
 
{/* 
            <div className='pb-5'>
                <ContactForm />
            </div>                           */}
            
        </>
    )
}
export default Home;